import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-seo-service',
  templateUrl: './seo-service.component.html',
  styleUrls: ['./seo-service.component.css'],
})
export class SeoServiceComponent {
  constructor(private titleService: Title, private metaTagService: Meta) {}

  ngOnInit() {
    // Meta Title , Description , keywords
    this.titleService.setTitle(
      'Search Engine Optimization Company in Dubai | Pro Web'
    );
    this.metaTagService.updateTag({
      name: 'description',
      content:
        "Maximize your digital footprint with Pro Web's top-notch SEO services in Dubai. Drive organic traffic, boost rankings, and secure top positions on search engines.",
    });
    this.metaTagService.updateTag({
      name: 'keywords',
      content:
        'search engine optimization,search engine optimization services, seo, seo company',
    });
  }

  logo = [
    '/assets/img/servicesdev/web-design/whites_logo.webp',
    '/assets/img/servicesdev/web-design/geohoney_logo.webp',
    '/assets/img/servicesdev/web-design/neo_reality_logo.webp',
  ];

  logo2 = [
    '/assets/img/servicesdev/web-design/whites_logo.webp',
    '/assets/img/servicesdev/web-design/geohoney_logo.webp',
    '/assets/img/servicesdev/web-design/neo_reality_logo.webp',
  ];

  cardsItems = [
    {
      title: 'On-Page Seo Optimization',
      desc: "At Pro Web, we take a holistic approach to on-page SEO optimization, focusing on key factors that influence search engine rankings. Our dedicated team conducts in-depth keyword research to identify your business's most relevant and high-impact keywords. We aim to maximize your site's visibility in search engine results by strategically placing and optimizing these keywords in your website's content, meta tags, and headers. Our team is well-versed in the latest SEO best practices and algorithm updates, ensuring that your on-page elements align with current search engine requirements.",
      img: 'assets/img/servicesdev/globe.webp',
    },
    {
      title: 'Keyword Research & Analysis',
      desc: 'Our first step in the Keyword Research and Analysis process is profoundly understanding your industry, target audience, and business goals. We collaborate closely with you to identify key themes, products, and services that define your brand. This collaborative effort ensures our target keywords are relevant and aligned with your unique value proposition. Our team uses advanced tools and industry expertise to identify high-impact keywords with the optimal search volume and competition balance.',
      img: 'assets/img/servicesdev/globe.webp',
    },
    {
      title: 'Technical Website Evaluation Or Audit',
      desc: "At Pro Web, we understand that a robust online presence begins with a technically sound website. Our website evaluation services are designed to comprehensively analyze your website's technical infrastructure, identifying areas for improvement to enhance performance, user experience, and search engine rankings. Our technical website evaluation includes a detailed assessment of page load times and performance optimizations. By streamlining your website's loading speed, we enhance the user experience, reduce bounce rates, and contribute to improved search engine rankings.",
      img: 'assets/img/servicesdev/globe.webp',
    },
    {
      title: 'Off Page SEO/ Link Building',
      desc: "At Pro Web, we recognize the significance of backlinks in establishing your website as a trusted and authoritative source in your industry. Our team employs a meticulous and ethical approach to link building, focusing on acquiring high-quality links from reputable and relevant websites. Through strategic outreach and relationship-building, we cultivate a network of authoritative backlinks contribute to your website's credibility and visibility in search engine results. Our team conducts in-depth research to identify authoritative websites and influencers in your industry.",
      img: 'assets/img/servicesdev/globe.webp',
    },
    {
      title: 'Mobile Search Improving',
      desc: "Our approach to Mobile Search Improvement begins with a comprehensive analysis of your website's mobile responsiveness. We understand that user experience is key, and our team meticulously evaluates your site's design, navigation, and functionality across various mobile devices. By identifying and addressing any issues hindering mobile performance, we optimize your website to deliver a smooth and user-friendly experience. Whether implementing mobile-friendly schema markup or optimizing for voice search, we ensure your website remains at the forefront of mobile search advancements.",
      img: 'assets/img/servicesdev/globe.webp',
    },
    {
      title: 'Competitors Monitoring',
      desc: 'Pro Web leverages advanced tools and analytics to provide real-time data and actionable recommendations. We identify and track key competitors in your niche, evaluating their digital presence, marketing strategies, and online performance. This in-depth analysis allows us to benchmark your brand against competitors, identifying strengths, weaknesses, opportunities, and threats. Our competitor monitoring reports deliver insights into competitor’s online performance, allowing you to make informed decisions to strengthen your competitive position.',
      img: 'assets/img/servicesdev/globe.webp',
    },
  ];

  dataItems = [
    {
      heading: 'Keyword Optimization',
      description:
        "Elevate your website's visibility by strategically incorporating relevant keywords into the content, meta tags, and headers. This targeted approach ensures a higher ranking on search engine results pages (SERPs), driving more organic traffic to your site.",
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Quality Content',
      description:
        'Our commitment to quality ensures that your content meets user intent. By delivering valuable, informative, and engaging material, we satisfy your audience and establish your website as a trusted source within your niche. Quality content is key to achieving and maintaining high search engine rankings.',
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Technical SEO Ranking',
      description:
        'Technical SEO is critical for website ranking. It involves optimizations like website speed, mobile responsiveness, and secure connections. Efficient implementation ensures effective crawling and indexing, contributing to improved search engine rankings and enhancing the technical foundation for higher visibility.',
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Mobile Responsiveness',
      description:
        'Adapt seamlessly to the growing mobile user base. Our mobile responsiveness ensures that your website provides a positive and consistent user experience across various devices. This meets user expectations and aligns with search engine preferences, as mobile-friendly sites are prioritized in search results.',
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Page Loading Speed',
      description:
        'Optimizing page loading speed enhances user satisfaction and search engine rankings. Faster-loading pages contribute to lower bounce rates and increased engagement, signaling to search engines that your website provides a high-quality experience, ultimately leading to improved rankings.',
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Internal Linking',
      description:
        'Internal linking establishes a structured hierarchy and improves navigation. You distribute page authority and enhance the overall user experience by strategically connecting relevant pages within your website. Internal linking encourages visitors to explore more of your content, increasing engagement.',
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Backlink Building',
      description:
        "Boost your website's authority by acquiring high-quality backlinks from reputable sources within your industry. Search engines consider authoritative and relevant backlinks as a sign of trustworthiness and credibility, positively impacting your rankings.",
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'User Experience (UX) Design',
      description:
        'Prioritize an intuitive and engaging user interface. Our UX design ensures a positive interaction with your site, focusing on straightforward navigation, easy accessibility, and an appealing design. Positive user signals contribute to improved search engine rankings.',
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'XML Sitemap',
      description:
        "Optimize search engine indexing with a well-organized XML sitemap. Providing a roadmap of your website's structure ensures that search engine crawlers can efficiently discover and index your content, contributing to better search engine rankings and increased organic traffic.",
      bgimg: 'hosting-card.png',
    },
  ];

  iconsdata = [
    {
      icon: 'assets/img/servicesdev/link_building_icon.svg',
      name: 'Link Building',
    },
    {
      icon: 'assets/img/servicesdev/content_creation_icon.svg',
      name: 'Content Creation',
    },
    {
      icon: 'assets/img/servicesdev/icons8-google.svg',
      name: 'Google Partner',
      link: 'https://www.google.com/partners/agency?id=2237427351&_gl=1*1tt2qqj*_ga*MzM3NjY1MjE1LjE2MjQwMTkyNjU.*_ga_V9K47ZG8NP*MTcxNTI2Mjc2OC4xOS4wLjE3MTUyNjI3NjguNjAuMC4w',
    },
    {
      icon: 'assets/img/servicesdev/technical_auditing_icon.svg',
      name: 'Technical Auditing',
    },
    {
      icon: 'assets/img/servicesdev/transparent_reporting.svg',
      name: 'Transparent Reporting',
    },
  ];

  carouselConfig2 = {
    autoplay: true,
    autoplaySpeed: 0,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    speed: 7000,
    arrows: false,
    dots: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  processdata = [
    {
      icon: '/assets/img/servicesdev/process-way/analysis-orange.png',
      heading: 'Analysis and Planning',
      description:
        'Through thorough research and analysis, we meticulously gather comprehensive information on market trends and target audiences. This information forms the foundation for our precise planning, where we strategically determine the technologies and tools required for project execution. Additionally, we define the website layout and modules, ensuring a well-thought-out framework for the development of a feasible website.',
    },

    {
      icon: '/assets/img/servicesdev/process-way/design-orange.png',
      heading: 'Design & Development',
      description:
        "During the design phase, our focus is on wireframing and creating user interfaces that align with the specific requirements of our clients. Our development process is guided by a keen awareness of online market trends, aiming to captivate the audience's attention through thoughtful design and functionality. We strive to create websites that not only meet but exceed customer expectations.",
    },

    {
      icon: '/assets/img/servicesdev/process-way/quality-orange.png',
      heading: 'Quality Analysis ',
      description:
        'Our dedicated Quality Assurance (QA) team conducts continuous testing to guarantee that the websites are devoid of technical errors, critical bugs, and other code-related issues. This rigorous testing process ensures that the developed applications seamlessly serve their intended purpose, delivering a reliable and user-friendly experience.',
    },
    {
      icon: '/assets/img/servicesdev/process-way/deploy-orange.png',
      heading: 'Deployment',
      description:
        'Upon successful resolution of all technical issues identified during the QA phase, we proceed to launch the websites on online platforms. This deployment phase marks the transition from development to the live environment. Throughout this stage, we actively monitor the websites, gaining insights into user interactions and making any necessary adjustments to optimize performance and user experience.',
    },
  ];

  carouselConfig = {
    autoplay: false,
    autoplaySpeed: 0,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 1000,
    arrows: true,
    dots: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  data = [
    'ICC.png',
    'avea.png',
    'landmark-group-logo.png',
    'Whites-white-logo.png',
    'luxury-gallery-logo.png',
  ];

  datamobile = [
    'ICC.png',
    'avea.png',
    'Whites-white-logo.png',
    'luxury-gallery-logo.png',
  ];

  faq = [
    {
      title: 'What is Search Engine Optimization?',
      des: '<p> Search Engine Optimization (SEO) is the process of optimizing a website or web page to increase its visibility and ranking in search engine results pages (SERPs). The ultimate goal of SEO is to drive organic (non-paid) traffic to the website by improving its relevance, authority, and trustworthiness in the eyes of search engines like Google, Bing, Yahoo etc.</p>',
      isActive: false,
    },
    {
      title: 'Why is search engine optimization important for businesses?',
      des: '<p>SEO boosts online visibility, driving more traffic and enhancing credibility. It improves user experience, offers cost-effective marketing, and enables businesses to outperform competitors, leading to better conversions and informed decision-making.</p>',
      isActive: false,
    },
    {
      title: 'What do your SEO services include?',
      des: "<p> Our SEO services encompass comprehensive keyword research, on-page optimization, off-page strategies such as link building, technical SEO, content creation, and regular performance monitoring to maximize your website's visibility and drive organic traffic.</p>",
      isActive: false,
    },
    {
      title: 'How do I choose the right SEO company for my business?',
      des: '<p>Pro Web assists you in selecting an SEO company by emphasizing their experience, expertise, and proven track record. We advocate for transparent communication, tailored strategies, and a commitment to ethical practices. With Pro Web, you can review client testimonials and case studies to ensure alignment with your business goals and expectations.</p>',
      isActive: false,
    },
    {
      title: 'How long does it take to see results?',
      des: '<p>The time to see SEO results varies based on website history, competition, and strategy. Typically, noticeable improvements may take 4-6 months, with more significant gains over 6-12 months. However, ongoing optimization ensures sustained growth beyond initial milestones.</p>',
      isActive: false,
    },
    {
      title: 'What are your strategies for ranking websites quickly on Google?',
      des: '<p> At Pro Web, our approach involves comprehensive keyword research, meticulous on-page optimization, high-quality content creation, strategic backlink building, and technical SEO enhancements. By employing these methods efficiently and ethically, we aim to elevate website rankings on Google while ensuring long-term sustainability quickly.</p>',
      isActive: false,
    },
    {
      title:
        'Is SEO a one-time effort, or does it require ongoing maintenance?',
      des: '<p>SEO is not a one-time effort; it requires ongoing maintenance. Search engine algorithms evolve, and competition changes, demanding continuous optimization to maintain and improve rankings. Regular updates, content creation, and monitoring are vital for sustained success in the competitive online landscape.</p>',
      isActive: false,
    },
    {
      title: 'How can I measure the effectiveness of my SEO efforts?',
      des: '<p>Using tools like Google Analytics and Google Search Console, you can measure SEO effectiveness through various metrics like organic traffic, keyword rankings, conversion rates, and bounce rates. Regular monitoring and analysis help gauge performance and identify areas for improvement to ensure optimal website results.</p>',
      isActive: false,
    },
  ];

  toggleAccordian(event: any, index: any) {
    this.faq.forEach((item, i) => {
      if (i !== index) {
        item.isActive = false;
      }
    });

    // Toggle the clicked item
    this.faq[index].isActive = !this.faq[index].isActive;
  }

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    infinite: false,
  };
}
