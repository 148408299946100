import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { FeaturesComponent } from './features/features.component';
import { FeaturesDetailsComponent } from './features-details/features-details.component';
import { ServicesComponent } from './services/services.component';
import { BlogComponent } from './blog/blog.component';
import { BlogDetailsComponent } from './blog/blog-details/blog-details.component';
import { CategoryBlogsComponent } from './category-blogs/category-blogs.component';
import { PricingComponent } from './pricing/pricing.component';
import { FaqComponent } from './faq/faq.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { DevelopersComponent } from './developers/developers.component';
import { CareersComponent } from './careers/careers.component';
import { WebDevelopmentComponent } from './web-development/web-development.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfServicesComponent } from './terms-of-services/terms-of-services.component';
import { RefundPolicyComponent } from './refund-policy/refund-policy.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { SitemapComponent } from './sitemap/sitemap.component';
import { CareersDetailsComponent } from './careers/careers-details/careers.component';
import { SeoComponent } from './seo-packages/seo-packages.component';
import { SmmPackageComponent } from './smm-packages/smm-package.component';
import { WebMaintenancePackageComponent } from './web-maintenance-packages/web-maintenance-package.component';
import { MobileAppComponent } from './mobile-app-development-company-in-dubai/mobile-app.component';
import { EcommerceDevelopmentCompanyInDubaiComponent } from './ecommerce-development-company-in-dubai/ecommerce-development-company-in-dubai.component';
import { PpcPackageComponent } from './ppc-packages/ppc-package.component';
import { WebHostingPackageComponent } from './web-hosting-packages/web-hosting-package.component';
import { WebDesignComponent } from './web-design/web-design.component';
import { WebHostingServiceComponent } from './web-hosting-service/web-hosting-service.component';
import { CloudHostingComponent } from './cloud-hosting/cloud-hosting.component';
import { BlockchainServiceComponent } from './blockchain-service/blockchain-service.component';
import { SeoServiceComponent } from './seo-service/seo-service.component';
import { DedicatedTeamComponent } from './dedicated-team/dedicated-team.component';
import { MachineLearningServiceComponent } from './machine-learning-service/machine-learning-service.component';
import { FlutterComponent } from './flutter/flutter.component';
import { ArtificialIntelligenceComponent } from './artificial-intelligence/artificial-intelligence.component';
import { ReactNativeComponent } from './react-native/react-native.component';
import { NativeComponent } from './native/native.component';
import { QualityAssuranceComponent } from './quality-assurance/quality-assurance.component';
import { NodejsComponent } from './nodejs/nodejs.component';
import { VaptComponent } from './vapt/vapt.component';
import { PpcServiceComponent } from './ppc-service/ppc-service.component';
import { SmmServiceComponent } from './smm-service/smm-service.component';
import { CanonicalResolver } from './resolvers/canonical.resolver';
import { StickyQuoateAnimationComponent } from './sticky-quoate-animation/sticky-quoate-animation.component';
import { QuoateModelComponent } from './quoate-model/quoate-model.component';
// import * as path from 'path';
const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./homepage/homepage.module').then((m) => m.HomepageModule),
		resolve: { canonical: CanonicalResolver }
	},
	{
		path: 'about-us',
		loadChildren: () => import('./about-page/about-page.module').then((m) => m.AboutPageModule),
		resolve: { canonical: CanonicalResolver }
	},
	{
		path: 'contact-us',
		loadChildren: () => import('./contact-page/contact-page.module').then((m) => m.ContactPageModule),
		resolve: { canonical: CanonicalResolver }
	},
	{
		path: 'case-study',
		loadChildren: () => import('./case-study-page/case-study-page.module').then((m) => m.CaseStudyPageModule),
		resolve: { canonical: CanonicalResolver }
	},
	{
		path: 'case-study/:id',
		loadChildren: () => import('./case-study-detail-page/case-study-detail-page.module').then((m) => m.CaseStudyDetailPageModule),
		resolve: { canonical: CanonicalResolver }
	},
	{
		path: 'portfolio',
		loadChildren: () => import('./portfolio-page/portfolio-page.module').then((m) => m.PortfolioPageModule),
		resolve: { canonical: CanonicalResolver }
	},
	// { 
	// 	path: 'hire-dedicated-developers/dubai', 
	// 	loadChildren: () => import('./hire-developer-page/hire-developer-page.module').then(m => m.HireDeveloperPageModule) 
	// },

	// { path: 'case-study', component: CaseStudiesComponent },
	// { path: 'case-study/:id', component: CaseStudyDetailComponent },
	// { path: 'about-us', component: AboutComponent },
	// { path: 'contact-us', component: ContactComponent },
	{ 
		path: 'hire-dedicated-developers/dubai', 
		component: DedicatedTeamComponent,
		resolve: { canonical: CanonicalResolver }
	},
	{
		path:'sticky-quoate-animation.component',
		component:StickyQuoateAnimationComponent
	},
	{
		path:'quoate-model.component',
		component:QuoateModelComponent
	}
   ,
	{ 
		path: 'web-design-company/dubai', 
		component: WebDesignComponent,
		resolve: { canonical: CanonicalResolver }
	},
	{ 
		path: 'web-development-company/dubai', 
		component: WebDevelopmentComponent,
		resolve: { canonical: CanonicalResolver }
	},
	{
		path: 'ecommerce-development-company/dubai',
		component: EcommerceDevelopmentCompanyInDubaiComponent,
		resolve: { canonical: CanonicalResolver }
	},
	{
		path: 'mobile-app-development-company/dubai',
		component: MobileAppComponent,
		resolve: { canonical: CanonicalResolver }
	},
	{ 
		path: 'web-hosting-company/dubai', 
		component: WebHostingServiceComponent,
		resolve: { canonical: CanonicalResolver }
	},
	{ 
		path: 'cloud-hosting-company/dubai', 
		component: CloudHostingComponent,
		resolve: { canonical: CanonicalResolver }
	},
	{
		path: 'blockchain-development-company/dubai',
		component: BlockchainServiceComponent,
		resolve: { canonical: CanonicalResolver }
	},
	{ 
		path: 'seo-company/dubai', 
		component: SeoServiceComponent,
		resolve: { canonical: CanonicalResolver }
	},
	{ 
		path: 'careers', 
		component: CareersComponent,
		resolve: { canonical: CanonicalResolver }
	},
	{ 
		path: 'careers-details/:slug', 
		component: CareersDetailsComponent,
		resolve: { canonical: CanonicalResolver }
	},
	{ 
		path: 'blog', 
		component: BlogComponent,
		resolve: { canonical: CanonicalResolver }
	},
	{ 
		path: 'blog/:id', 
		component: BlogDetailsComponent,
		resolve: { canonical: CanonicalResolver }
	},
	{ 
		path: 'blog/category/:id', 
		component: CategoryBlogsComponent,
		resolve: { canonical: CanonicalResolver }
	},
	{ 
		path: 'seo-packages', 
		component: SeoComponent,
		resolve: { canonical: CanonicalResolver }
	},
	{ 
		path: 'ppc-packages', 
		component: PpcPackageComponent,
		resolve: { canonical: CanonicalResolver }
	},
	{ 
		path: 'smm-packages', 
		component: SmmPackageComponent,
		resolve: { canonical: CanonicalResolver }
	},
	{
		path: 'website-maintenance-packages',
		component: WebMaintenancePackageComponent,
		resolve: { canonical: CanonicalResolver }
	},
	{ 
		path: 'web-hosting-packages', 
		component: WebHostingPackageComponent,
		resolve: { canonical: CanonicalResolver }
	},
	{ 
		path: 'privacy-policy', 
		component: PrivacyPolicyComponent,
		resolve: { canonical: CanonicalResolver }
	},
	{ 
		path: 'terms-and-conditions', 
		component: TermsOfServicesComponent,
		resolve: { canonical: CanonicalResolver }
	},
	{ 
		path: 'sitemap', 
		component: SitemapComponent,
		resolve: { canonical: CanonicalResolver }
	},
	{ 
		path: 'thankyou', 
		component: ThankyouComponent,
		resolve: { canonical: CanonicalResolver }
	},
	{ 
		path: 'developers', 
		component: DevelopersComponent,
		resolve: { canonical: CanonicalResolver }
	},
	{ 
		path: 'features', 
		component: FeaturesComponent,
		resolve: { canonical: CanonicalResolver }
	},
	{ 
		path: 'features-details', 
		component: FeaturesDetailsComponent,
		resolve: { canonical: CanonicalResolver }
	},
	{ 
		path: 'services', 
		component: ServicesComponent,
		resolve: { canonical: CanonicalResolver }
	},
	{ 
		path: 'pricing', 
		component: PricingComponent,
		resolve: { canonical: CanonicalResolver }
	},
	{ 
		path: 'faq', 
		component: FaqComponent,
		resolve: { canonical: CanonicalResolver }
	},
	{ 
		path: 'coming-soon', 
		component: ComingSoonComponent,
		resolve: { canonical: CanonicalResolver }
	},
	{ 
		path: 'refund-policy', 
		component: RefundPolicyComponent,
		resolve: { canonical: CanonicalResolver }
	},
	{
		path: 'machine-learning/dubai',
		component: MachineLearningServiceComponent,
		resolve: { canonical: CanonicalResolver }
	},
	{ 
		path: 'flutter-app-development/dubai', 
		component: FlutterComponent,
		resolve: { canonical: CanonicalResolver }
	},
	{ 
		path: 'ai-development/dubai', 
		component: ArtificialIntelligenceComponent,
		resolve: { canonical: CanonicalResolver }
	},
	{
		path: 'react-native-app-development/dubai',
		component: ReactNativeComponent,
		resolve: { canonical: CanonicalResolver }
	},
	{ 
		path: 'native-app-development/dubai', 
		component: NativeComponent,
		resolve: { canonical: CanonicalResolver }
	},
	{
		path: 'software-quality-assurance/dubai',
		component: QualityAssuranceComponent,
		resolve: { canonical: CanonicalResolver }
	},
	{ 
		path: 'node-js-development/dubai', 
		component: NodejsComponent,
		resolve: { canonical: CanonicalResolver }
	},
	{ 
		path: 'vapt-testing/dubai', 
		component: VaptComponent,
		resolve: { canonical: CanonicalResolver }
	},
	{ 
		path: 'adwords-agency/dubai', 
		component: PpcServiceComponent,
		resolve: { canonical: CanonicalResolver }
	},
	{ 
		path: 'social-media-marketing-company/dubai', 
		component: SmmServiceComponent,
		resolve: { canonical: CanonicalResolver }
	},
	{ path: '**', component: NotFoundComponent },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', preloadingStrategy: PreloadAllModules }),
	],
	exports: [RouterModule],
})

export class AppRoutingModule { }
