<!-- service banner -->
<app-servicebanner heading="Hire Software & App Developers on Contract Basis"
    paragraph="Founders from Bookmypet, Springboard, and The Travel Hub trusted us! Experience our commitment towards dedicated hiring, ensuring the right talent for your success."
    bannerimg="assets/img/servicesdev/webdesignbg.webp" mobileImg="dedicated-banner.svg" [cards]="logo" [cards2]="logo2"
    [tagline]="taglinearr"></app-servicebanner>


<!-- breadcrumbs -->

<nav aria-label="breadcrumb" class="ps-5 pt-3 pt-md-0 d-none d-md-block">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="/">Home</a></li>
        <li class="breadcrumb-item active">Hire Developers</li>
    </ol>
</nav>

<section class="wrapper">
    <!-- partnership model -->
    <section class="container py-3">
        <h2 class="dedicated-title"> Hire Web and Mobile App <span> Developers in Dubai </span></h2>

        <ul class="nav nav-pills my-5 justify-content-between align-items-center flex-nowrap" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
                <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home"
                    type="button" role="tab" aria-controls="pills-home" aria-selected="true"> <img src=""
                        class="mx-2 d-none d-md-inline-block" width="30" alt="" srcset=""> Fixed Price</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile"
                    type="button" role="tab" aria-controls="pills-profile" aria-selected="false"> <img src=""
                        class="mx-2 d-none d-md-inline-block" width="30" alt="" srcset=""> Dedicated Hiring</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact"
                    type="button" role="tab" aria-controls="pills-contact" aria-selected="false"> <img src=""
                        class="mx-2 d-none d-md-inline-block" width="30" alt="" srcset=""> Time & Material</button>
            </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">

                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div>
                            <h5 class="fw-bold mb-3">Fixed Price</h5>
                            <div>
                                <img src="../../assets/img/servicesdev/dedicated-team/fixed-price.png"
                                    class="d-inline d-md-none my-2" alt="" srcset="">
                            </div>
                            <p>The Fixed Price model is ideal for startups and projects with well-defined scopes and
                                features.
                                Before commencing development, we determine the necessary time and budget to deliver an
                                MVP and
                                manage all associated tasks.</p>
                            <p>This pricing structure is typically determined by a set amount of work and remains
                                unchanged once
                                the contract is finalized. It is most effective when you have clear project
                                requirements, set
                                deadlines, or a constrained software development budget.</p>
                            <p>Key features of this model include:</p>
                            <div>

                            </div>
                            <div class="partnership-list">
                                <p> <img src="../../assets/img/servicesdev/dedicated-team/timeframe-icon.svg"
                                        class="me-2" alt="" srcset=""> Specific timeframes</p>
                                <p> <img src="../../assets/img/servicesdev/dedicated-team/comprehnsive-icon.svg"
                                        class="me-2" alt="" srcset=""> Comprehensive specifications</p>
                                <p><img src="../../assets/img/servicesdev/dedicated-team/short-term-icon.svg"
                                        class="me-2" alt="" srcset=""> Short-term projects or MVPs</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div>
                            <img src="../../assets/img/servicesdev/dedicated-team/fixed-price.png"
                                class="d-none d-md-inline img-fluid" alt="" srcset="">
                        </div>
                    </div>
                </div>

            </div>
            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div>
                            <h5 class="fw-bold mb-3">Dedicated Hiring</h5>
                            <img src="../../assets/img/servicesdev/dedicated-team/dedicated-hiring.png"
                                class="d-inline d-md-none my-2" alt="" srcset="">
                            <p>The Dedicated Hiring model is tailored for enterprise-level projects, offering two
                                primary scenarios: hiring a Dedicated developer to create a product from the ground up
                                or bringing on a Dedicated developer for ongoing development needs.</p>

                            <p>Whether you require a complete project team or additional developers to augment your
                                in-house team for specific tasks, this hiring model is an excellent fit. You can either
                                manage the project yourself or delegate all management responsibilities to us.</p>

                            <p>Key attributes of this model include:</p>
                            <div class="partnership-list">
                                <p> <img src="../../assets/img/servicesdev/dedicated-team/timeframe-icon.svg"
                                        class="me-2" alt="" srcset=""> Ideal for intricate projects</p>
                                <p> <img src="../../assets/img/servicesdev/dedicated-team/comprehnsive-icon.svg"
                                        class="me-2" alt="" srcset=""> Long-term partnership</p>
                                <p><img src="../../assets/img/servicesdev/dedicated-team/short-term-icon.svg"
                                        class="me-2" alt="" srcset=""> Flexible involvement levels</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div>
                            <img src="../../assets/img/servicesdev/dedicated-team/dedicated-hiring.png"
                                class="d-none d-md-inline" alt="" srcset="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div>
                            <h5 class="fw-bold mb-3">Time & Material</h5>
                            <img src="../../assets/img/servicesdev/dedicated-team/time-material.png"
                                class="d-inline d-md-none my-2" alt="" srcset="">
                            <p>The Time and Material model is particularly well-suited for small to mid-sized
                                businesses, especially for complex, long-term projects. It's also apt for ongoing
                                projects in testing or bug-fixing phases, as well as those needing maintenance or
                                support.</p>
                            <p>With this approach, we focus on estimating and agreeing upon specific scopes of work
                                rather than the entire project, allowing for greater flexibility in time management and
                                planning.</p>
                            <p>Key characteristics of this model include:</p>
                            <div class="partnership-list">
                                <p> <img src="../../assets/img/servicesdev/dedicated-team/timeframe-icon.svg"
                                        class="me-2" alt="" srcset="">Projects with vague initial concepts</p>
                                <p> <img src="../../assets/img/servicesdev/dedicated-team/comprehnsive-icon.svg"
                                        class="me-2" alt="" srcset=""> Unclear target markets</p>
                                <p><img src="../../assets/img/servicesdev/dedicated-team/short-term-icon.svg"
                                        class="me-2" alt="" srcset=""> High demands for control and oversight</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div>
                            <img src="../../assets/img/servicesdev/dedicated-team/time-material.png"
                                class="d-none d-md-inline" alt="" srcset="">
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </section>

    <!-- diverce project -->
    <section class="container py-5 diverse">
        <h2 class="dedicated-title">Diverse Project<span> Resources </span></h2>
        <p class="text-center">At Pro Web, our vast team of experienced tech professionals, proficient in the latest
            technologies and frameworks, keeps us at the forefront of innovation. With our agile and progressive
            mindset, we continually surpass industry norms, maintaining our leadership in the evolving tech landscape.
        </p>

        <div class="row m-t-40 g-2">
            <div class="col-md-4 m-b-15" *ngFor="let item of diverce_project">
                <div class="team-card">
                    <img src={{item.image_name}} width="100%" alt="" srcset="">
                    <h4 class="fw-600 text-center my-3">{{item.title}}</h4>
                </div>
            </div>

        </div>

    </section>

    <!-- popular brands -->
    <section class="dedicated-team-benefit">
        <div class="container">
            <div class="dedicated-team-benefit-blog">

                <div class="project-section-logo">
                    <h2 class="dedicated-title mb-4">
                        Popular Brands Who <img src="assets/img/webp-image/home/hearticonhome.webp" width="41"
                            height="41" alt="heart" style="display: inline-block;" class="heart-icon"> <span> Pro Web
                            For
                            Remote
                            Hiring</span>
                    </h2>

                    <div>
                        <ngx-slick-carousel class="carousel owl-carousel brandSlider" [config]="carouselConfig">
                            <div ngxSlickItem *ngFor="let project of projects">
                                <div class="col-lg-12">
                                    <div class="industries industriessection">
                                        <div class="industries-content">
                                            <div class="industries-info">
                                                <img src="{{project.img}}" width="121" height="121" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ngx-slick-carousel>
                    </div>

                </div>

            </div>
        </div>
    </section>

    <section class="dedicated-team-benefit bg-color-light-blue-gradient">
        <div class="">
            <div class="dedicated-team-benefit-blog">
                <h2 class="dedicated-title">Streamline <span>Your Remote Developer Hiring with Easy Steps</span>
                </h2>

                <!-- the proweb way section -->
                <section class="spacing">
                    <div class="proweb-padding">
                        <div class="row justify-content-center processWrap">

                            <div class="d-none d-md-flex p-3">

                                <div class="row px-0 md:px-5">

                                    <div class="col">
                                        <div class="cardwrap">
                                            <div class="text-start p-3">
                                                <div class="d-flex">

                                                    <img src="../../assets/img/servicesdev/process-way/tell-about-gray.png"
                                                        class="gray" alt="" srcset="">
                                                    <img src="../../assets/img/servicesdev/process-way/tell-about-orange.png"
                                                        class="orange" alt="" srcset="">



                                                    <img src="../../assets/img/servicesdev/straight-arrow2.svg"
                                                        class="top-arrow" alt="" srcset="">

                                                </div>

                                            </div>
                                            <div class="cardcontent">
                                                <h5 class="text-start fw-bold">Tell Us About Your Project</h5>
                                                <p>Start by sharing the details of your project with us. We'll work
                                                    closely with you to understand your requirements, technical needs,
                                                    and specific expectations for the remote developer role.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col">
                                        <div class="cardwrap">
                                            <div class="text-start p-3">
                                                <div class="d-flex">

                                                    <img src="../../assets/img/servicesdev/process-way/analysis-skill-gray.png"
                                                        class="gray" alt="" srcset="">

                                                    <img src="../../assets/img/servicesdev/process-way/analysis-skill-orange.png"
                                                        class="orange" alt="" srcset="">


                                                    <img src="../../assets/img/servicesdev/straight-arrow2.svg"
                                                        class="top-arrow" alt="" srcset="">

                                                </div>

                                            </div>
                                            <div class="cardcontent">
                                                <h5 class="text-start fw-bold">Analyze Skills & Experience
                                                </h5>
                                                <p>Our team of experts will analyze and screen candidates based on their
                                                    skills, experience, and suitability for your project. We'll present
                                                    you with a curated list of qualified candidates who meet the
                                                    criteria.</p>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="col">
                                        <div class="cardwrap">
                                            <div class="text-start p-3">
                                                <div class="d-flex">
                                                    <img src="../../assets/img/servicesdev/process-way/interact-gray.png"
                                                        class="gray" alt="" srcset="">

                                                    <img src="../../assets/img/servicesdev/process-way/interact-orange.png"
                                                        class="orange" alt="" srcset="">

                                                    <img src="../../assets/img/servicesdev/straight-arrow2.svg"
                                                        class="top-arrow" alt="" srcset="">

                                                </div>

                                            </div>
                                            <div class="cardcontent">
                                                <h5 class="text-start fw-bold">Interact Via Live Interview</h5>
                                                <p>
                                                    You'll have the opportunity to interact with the shortlisted
                                                    candidates through live interviews. This allows you to evaluate
                                                    their technical proficiency, communication skills, and cultural fit
                                                    before making a decision.
                                                </p>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="col">
                                        <div class="cardwrap">
                                            <div class="text-start p-3">
                                                <div class="d-flex">
                                                    <img src="../../assets/img/servicesdev/process-way/agree-gray.png"
                                                        class="gray" alt="" srcset="">

                                                    <img src="../../assets/img/servicesdev/process-way/agree-orange.png"
                                                        class="orange" alt="" srcset="">

                                                    <img src="../../assets/img/servicesdev/straight-arrow2.svg"
                                                        class="top-arrow" alt="" srcset="">
                                                </div>

                                            </div>
                                            <div class="cardcontent">
                                                <h5 class="text-start fw-bold">Agree And Sign </h5>
                                                <p>
                                                    Once you've selected the ideal candidate, we'll assist you in
                                                    finalizing the terms of employment, including compensation, remote
                                                    work policies, and contractual agreements. Both parties will sign
                                                    off to seal the deal.
                                                </p>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="col">
                                        <div class="cardwrap">
                                            <div class="text-start p-3">
                                                <div class="d-flex">
                                                    <img src="../../assets/img/servicesdev/process-way/get-started-gray.png"
                                                        class="gray" alt="" srcset="">

                                                    <img src="../../assets/img/servicesdev/process-way/get-started-orange.png"
                                                        class="orange" alt="" srcset="">
                                                </div>

                                            </div>
                                            <div class="cardcontent">
                                                <h5 class="text-start fw-bold">Get Started In < 48 Hours</h5>
                                                        <p>
                                                            With Pro Web's efficient process, you can onboard your
                                                            chosen remote developer and kickstart your project in less
                                                            than 48 hours. We ensure a smooth transition and provide
                                                            ongoing support to help integrate the new team member
                                                            seamlessly.
                                                        </p>

                                            </div>
                                        </div>
                                    </div>


                                </div>



                            </div>


                            <div class="d-block d-md-none my-4">

                                <ngx-slick-carousel class="carousel owl-carousel branflex processSlider"
                                    [config]="carouselConfig4">

                                    <div class="cardwrap" ngxSlickItem class="slider-inlinefixe">
                                        <div class="cardcontent-mobile">
                                            <div class="text-center p-3">

                                                <img src='/assets/img/servicesdev/process-way/tell-about-orange.png'
                                                    class="orange" alt="" srcset="">

                                            </div>
                                            <h5 class="text-center fw-bold my-3">Tell Us About Your Project</h5>
                                            <p>Start by sharing the details of your project with us. We'll work
                                                closely with you to understand your requirements, technical needs,
                                                and specific expectations for the remote developer role.</p>
                                        </div>
                                    </div>

                                    <div class="cardwrap" ngxSlickItem class="slider-inlinefixe">
                                        <div class="cardcontent-mobile">
                                            <div class="text-center p-3">

                                                <img src='/assets/img/servicesdev/process-way/analysis-skill-orange.png'
                                                    class="orange" alt="" srcset="">

                                            </div>
                                            <h5 class="text-center fw-bold my-3">Analyze Skills & Experience</h5>
                                            <p>Our team of experts will analyze and screen candidates based on their
                                                skills, experience, and suitability for your project. We'll present
                                                you with a curated list of qualified candidates who meet the
                                                criteria.</p>
                                        </div>
                                    </div>

                                    <div class="cardwrap" ngxSlickItem class="slider-inlinefixe">
                                        <div class="cardcontent-mobile">
                                            <div class="text-center p-3">

                                                <img src='/assets/img/servicesdev/process-way/interact-orange.png'
                                                    class="orange" alt="" srcset="">

                                            </div>
                                            <h5 class="text-center fw-bold my-3">Interact Via Live Interview</h5>
                                            <p>
                                                You'll have the opportunity to interact with the shortlisted
                                                candidates through live interviews. This allows you to evaluate
                                                their technical proficiency, communication skills, and cultural fit
                                                before making a decision.
                                            </p>
                                        </div>
                                    </div>


                                    <div class="cardwrap" ngxSlickItem class="slider-inlinefixe">
                                        <div class="cardcontent-mobile">
                                            <div class="text-center p-3">

                                                <img src='/assets/img/servicesdev/process-way/agree-orange.png'
                                                    class="orange" alt="" srcset="">

                                            </div>
                                            <h5 class="text-center fw-bold mb-2">Agree And Sign </h5>
                                            <p>
                                                Once you've selected the ideal candidate, we'll assist you in
                                                finalizing the terms of employment, including compensation, remote
                                                work policies, and contractual agreements. Both parties will sign
                                                off to seal the deal.
                                            </p>
                                        </div>
                                    </div>


                                    <div class="cardwrap" ngxSlickItem class="slider-inlinefixe">
                                        <div class="cardcontent-mobile">
                                            <div class="text-center p-3">

                                                <img src='/assets/img/servicesdev/process-way/get-started-orange.png'
                                                    class="orange" alt="" srcset="">

                                            </div>
                                            <h5 class="text-center fw-bold my-3">Get Started In < 48 Hours</h5>
                                                    <p>
                                                        With Pro Web's efficient process, you can onboard your
                                                        chosen remote developer and kickstart your project in less
                                                        than 48 hours. We ensure a smooth transition and provide
                                                        ongoing support to help integrate the new team member
                                                        seamlessly.
                                                    </p>
                                        </div>
                                    </div>

                                </ngx-slick-carousel>

                            </div>

                        </div>
                    </div>
                </section>




            </div>
        </div>
    </section>

    <section class="dedicated-team-benefit p-b-0">
        <div class="container">
            <div class="dedicated-team-benefit-blog">
                <h2 class="dedicated-title  text-left">Traditional Hiring vs <span>Pro Web</span></h2>
                <p class="text-center">
                    A dedicated team is the best choice for large and long-term development projects.
                </p>
            </div>

            <div class="row py-5">
                <div class="col-md-3">
                    <h3 style="color: #4E4E4E;" class="text-center text-md-start">Traditional Hiring</h3>
                    <div class="list-top">

                        <div class="d-flex listpoint"> <img
                                src="../../assets/img/servicesdev/dedicated-team/cross-icon.svg" alt="" srcset="">
                            <p class="mx-2">Hiring time generally takes up to <b style="color: rgba(45, 111, 166, 1);">
                                    60-70 days</b> to hire.</p>
                        </div>

                        <div class="d-flex listpoint"> <img
                                src="../../assets/img/servicesdev/dedicated-team/cross-icon.svg" alt="" srcset="">
                            <p class="mx-2">Joining time takes <b style="color: rgba(45, 111, 166, 1);">30-45 days </b>
                                to join after the selection.</p>
                        </div>

                        <div class="d-flex listpoint"> <img
                                src="../../assets/img/servicesdev/dedicated-team/cross-icon.svg" alt="" srcset="">
                            <p class="mx-2">Transparency need an <b style="color: rgba(45, 111, 166, 1);"> extra team or
                                    tools </b> to manage reports.</p>
                        </div>

                        <div class="d-flex listpoint"> <img
                                src="../../assets/img/servicesdev/dedicated-team/cross-icon.svg" alt="" srcset="">
                            <p class="mx-2">Managers struggle to improve productivity & <b
                                    style="color: rgba(45, 111, 166, 1);">happiness score. </b> </p>
                        </div>

                        <div class="d-flex listpoint"> <img
                                src="../../assets/img/servicesdev/dedicated-team/cross-icon.svg" alt="" srcset="">
                            <p class="mx-2">Severance usually <b style="color: rgba(45, 111, 166, 1);"> 2-3 months </b>
                                of severance.</p>
                        </div>

                        <div class="d-flex listpoint"> <img
                                src="../../assets/img/servicesdev/dedicated-team/cross-icon.svg" alt="" srcset="">
                            <p class="mx-2"> <b style="color: rgba(45, 111, 166, 1);">Compliant management </b> in line
                                with UAE labor laws covers laptops, WiFi,
                                leaves, etc.</p>
                        </div>


                    </div>
                </div>
                <div class="col-md-6 text-center">
                    <div class="d-none d-md-block">
                        <img src="../../assets/img/servicesdev/traditional-hiring.png" alt="" srcset="">
                    </div>
                    <div class="d-block d-md-none my-3">
                        <img src="../../assets/img/servicesdev/dedicated-team/hiring_mobile.svg" alt="" srcset="">
                    </div>
                </div>
                <div class="col-md-3">
                    <h3 style="color: #4E4E4E;" class="text-center text-md-start">Pro Web Hiring</h3>
                    <div class="listhiring">

                        <div class="d-flex listpoint"> <img
                                src="../../assets/img/servicesdev/dedicated-team/right-orange.svg" alt="" srcset="">
                            <p class="mx-2">Hiring time takes upto <b style="color: rgba(247, 153, 58, 1);"> 7 business
                                    days </b> to hire.</p>
                        </div>

                        <div class="d-flex listpoint"> <img
                                src="../../assets/img/servicesdev/dedicated-team/right-orange.svg" alt="" srcset="">
                            <p class="mx-2">Joining time starts in <b style="color: rgba(247, 153, 58, 1);"> 2-3
                                    business days </b> on selection.</p>
                        </div>

                        <div class="d-flex listpoint"> <img
                                src="../../assets/img/servicesdev/dedicated-team/right-orange.svg" alt="" srcset="">
                            <p class="mx-2">Transparency-reports and <b style="color: rgba(247, 153, 58, 1);"> monthly
                                    reviews </b> are a click away.</p>
                        </div>

                        <div class="d-flex listpoint"> <img
                                src="../../assets/img/servicesdev/dedicated-team/right-orange.svg" alt="" srcset="">
                            <p class="mx-2">Satisfied <b style="color: rgba(247, 153, 58, 1);">customers recommend </b>
                                us gladly.</p>
                        </div>

                        <div class="d-flex listpoint"> <img
                                src="../../assets/img/servicesdev/dedicated-team/right-orange.svg" alt="" srcset="">
                            <p class="mx-2"> <b style="color: rgba(247, 153, 58, 1);">No Severance </b>when letting
                                people go.</p>
                        </div>

                        <div class="d-flex listpoint"> <img
                                src="../../assets/img/servicesdev/dedicated-team/right-orange.svg" alt="" srcset="">
                            <p class="mx-2">Compliant management <b style="color: rgba(247, 153, 58, 1);"> just one
                                    invoice </b> and a simple process.</p>
                        </div>


                    </div>
                </div>
            </div>

        </div>


    </section>

    <section class="strategic">
        <div class="container">
            <div class="row">
                <h2 class="dedicated-title mb-3">Strategic Hiring Partner <span>for Every Tech Need</span> </h2>

                <div class="d-flex justify-content-between align-items-center my-3 btn-pills newbtn-pillsMd">
                    <button (click)="slickSlideChange(slickModal, 0)" class="first-icon"
                        [class.active-button-one]="activeIndex === 0"> <img src="" class="mx-2 d-none d-md-inline"
                            alt="" srcset="">
                        Contract Model</button>
                    <button (click)="slickSlideChange(slickModal, 1)" class="second-icon"
                        [class.active-button-two]="activeIndex === 1"><img src="" class="mx-2 d-none d-md-inline" alt=""
                            srcset="">Remote
                        Hiring</button>
                    <button (click)="slickSlideChange(slickModal, 2)" class="third-icon"
                        [class.active-button-third]="activeIndex === 2"><img src="" class="mx-2 d-none d-md-inline"
                            alt="" srcset="">Hiring
                        Type</button>
                </div>


                <ngx-slick-carousel class="carousel owl-carousel clientSlider newClientSliderclss" #slickModal="slick-carousel"
                    [config]="carouselConfig2" (afterChange)="onAfterChange($event, 1)">

                    <div ngxSlickItem class="single-feedback" *ngFor="let item of hiring_partner">

                        <div class="row align-items-center" *ngIf="!item.isAnotherSlider">
                            <div class="col-xl-7">
                                <div>
                                    <img src={{item.hiringImg}} alt="" srcset="">
                                </div>
                            </div>
                            <div class="col-xl-5">
                                <div class="text-start content">
                                    <h2 class="fw-bold">{{item.hiringName}}</h2>
                                    <p class="py-3">{{item.hiringDesc}}</p>
                                </div>
                                <!-- <div class="orange-btn">
                                    <button>Get started ></button>
                                </div> -->
                            </div>
                        </div>

                        <div class="row" *ngIf="item.isAnotherSlider">
                            <ngx-slick-carousel class="carousel owl-carousel clientSlider2 newClientSlider2clss"
                                #slickModal2="slick-carousel" [config]="carouselConfig3"
                                (afterChange)="onAfterChangeNested($event, 2)">

                                <div ngxSlickItem class="single-feedback borderremove"
                                    *ngFor="let item2 of item.anotherSlider">
                                    <div class="row">
                                        <div class="col-md-7">
                                            <div>
                                                <img src={{item2.hiringImg}} alt="" srcset="">
                                            </div>
                                        </div>
                                        <div class="col-md-5">
                                            <div class="text-start content">
                                                <h2 class="fw-bold">{{item2.hiringName}}</h2>
                                                <p class="py-3">{{item2.hiringDesc}}</p>
                                            </div>
                                            <!-- <div class="orange-btn">
                                                <button>Get started ></button>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>

                            </ngx-slick-carousel>
                        </div>



                    </div>

                </ngx-slick-carousel>
            </div>


        </div>
    </section>

    <app-dedicated-hosting heading="Unlock limitless possibilities" subheading="with pro Web"
        [data]="dataItems"></app-dedicated-hosting>

    <section class="dedicated-team-benefit p-t0 my-5">
        <div style="border-bottom: 1px solid #ADC6DC;">
            <div class="container">
                <div class="dedicated-team-benefit-blog">
                    <h2 class="dedicated-title">Industry-Tailored <span>Innovation</span> </h2>
                    <div class="d-block d-md-none">
                        <img src="../../assets/img/servicesdev/industry-tailored.png" width="100%" alt="" srcset="">
                    </div>
                    <p class="text-center">If you're seeking dependable developers for industry-specific projects, your
                        search ends here! Our formidable team of experts is ready to provide tailored solutions that
                        precisely align with your unique business needs. With a proven track record of adaptability, we
                        offer the flexibility to navigate a broad spectrum of industry-specific challenges, ensuring
                        your
                        projects are in capable hands from conception to success. Our experienced team thrives on the
                        intricacies of industry-specific projects, backed by a deep understanding of the nuances that
                        drive
                        success in your sector. Partner with us to unlock the full potential of your projects and
                        achieve
                        lasting excellence in your industry.</p>
                </div>

                <div class="d-none d-md-block">
                    <img src="../../assets/img/servicesdev/industry-tailored.png" width="100%" alt="" srcset="">
                </div>
            </div>
        </div>

    </section>

    <section class="dedicated-team-benefit p-t0">
        <div class="container">
            <div class="dedicated-team-benefit-blog">
                <h2 class="dedicated-title">Start Your <span>Digital Transformation</span> </h2>
                <p class="text-center">Our dedicated software developers have a good grasp of the latest technologies
                    and platforms,<br />
                    which helps us excel in different industries . Reach out to discover our expertise in top
                    frameworks.
                </p>

                <div class="dediacetd-team-tabbing">
                    <ul class="custom-tabbing">
                        <li [ngClass]="{'active-tab': tabbing==1}" (click)="handleClick(1)">Mobile App</li>
                        <li [ngClass]="{'active-tab': tabbing==2}" (click)="handleClick(2)">Front- end</li>
                        <li [ngClass]="{'active-tab': tabbing==3}" (click)="handleClick(3)">Back-End</li>
                        <li [ngClass]="{'active-tab': tabbing==4}" (click)="handleClick(4)">e-Commerce</li>
                        <li [ngClass]="{'active-tab': tabbing==5}" (click)="handleClick(5)">database & Cloud</li>
                        <li [ngClass]="{'active-tab': tabbing==6}" (click)="handleClick(6)">Design & Motion</li>
                        <li [ngClass]="{'active-tab': tabbing==7}" (click)="handleClick(7)">QA & Testing</li>
                    </ul>
                    <div class="custom-tab-content">
                        <div class="tab tabing1" *ngIf="tabbing == 1">
                            <img src="assets/img/webp-image/dedicated-team/ma1.webp" width="100" height="100"
                                alt="image" />

                            <img src="assets/img/webp-image/dedicated-team/ma2.webp" width="100" height="100"
                                alt="image" />

                            <img src="assets/img/webp-image/dedicated-team/ma3.webp" width="100" height="100"
                                alt="image" />

                            <img src="assets/img/webp-image/dedicated-team/ma4.webp" width="100" height="100"
                                alt="image" />

                            <img src="assets/img/webp-image/dedicated-team/ma5.webp" width="100" height="100"
                                alt="image" />

                            <img src="assets/img/webp-image/dedicated-team/ma6.webp" width="100" height="100"
                                alt="image" />
                        </div>
                        <div class="tab tabing2" *ngIf="tabbing == 2">
                            <div class="logofront">
                                <img src="assets/img/webp-image/dedicated-team/ft1.webp" width="100" height="100"
                                    alt="image" />
                                <img src="assets/img/webp-image/dedicated-team/ft2.webp" width="100" height="100"
                                    alt="image" />
                                <img src="assets/img/webp-image/dedicated-team/ft3.webp" width="100" height="100"
                                    alt="image" />
                                <img src="assets/img/webp-image/dedicated-team/ft4.webp" width="100" height="100"
                                    alt="image" />
                                <img src="assets/img/webp-image/dedicated-team/ft5.webp" width="100" height="100"
                                    alt="image" />
                                <img src="assets/img/webp-image/dedicated-team/ft6.webp" width="100" height="100"
                                    alt="image" />
                                <img src="assets/img/webp-image/dedicated-team/ft7.webp" width="100" height="100"
                                    alt="image" />
                                <img src="assets/img/webp-image/dedicated-team/ft8.webp" width="100" height="100"
                                    alt="image" />
                                <img src="assets/img/webp-image/dedicated-team/ft9.webp" width="100" height="100"
                                    alt="image" />
                                <img src="assets/img/webp-image/dedicated-team/ft10.webp" width="100" height="100"
                                    alt="image" />
                                <img src="assets/img/webp-image/dedicated-team/ft11.webp" width="100" height="100"
                                    alt="image" />
                                <img src="assets/img/webp-image/dedicated-team/ft12.webp" width="100" height="100"
                                    alt="image" />
                            </div>


                        </div>
                        <div class="tab tabing3" *ngIf="tabbing == 3">
                            <div class="logofront">
                                <img src="assets/img/webp-image/dedicated-team/bt3.webp" width="100" height="100"
                                    alt="image" />
                                <img src="assets/img/webp-image/dedicated-team/bt7.webp" width="100" height="100"
                                    alt="image" />
                                <img src="assets/img/webp-image/dedicated-team/bt1.webp" width="100" height="100"
                                    alt="image" />
                                <img src="assets/img/webp-image/dedicated-team/bt2.webp" width="100" height="100"
                                    alt="image" />
                                <img src="assets/img/webp-image/dedicated-team/bt9.webp" width="100" height="100"
                                    alt="image" />
                                <img src="assets/img/webp-image/dedicated-team/bt10.webp" width="100" height="100"
                                    alt="image" />
                                <img src="assets/img/webp-image/dedicated-team/bt-last.webp" width="100" height="100"
                                    alt="image" />

                            </div>
                        </div>
                        <div class="tab tabing4" *ngIf="tabbing == 4">
                            <img src="assets/img/webp-image/dedicated-team/bt4.webp" width="100" height="100"
                                alt="image" />
                            <img src="assets/img/webp-image/dedicated-team/bt5.webp" width="100" height="100"
                                alt="image" />
                            <img src="assets/img/webp-image/dedicated-team/bt6.webp" width="100" height="100"
                                alt="image" />
                            <img src="assets/img/webp-image/dedicated-team/et1.webp" width="100" height="100"
                                alt="image" />
                            <img src="assets/img/webp-image/dedicated-team/et2.webp" width="100" height="100"
                                alt="image" />
                            <img src="assets/img/webp-image/dedicated-team/et3.webp" width="100" height="100"
                                alt="image" />
                        </div>
                        <div class="tab tabing5" *ngIf="tabbing == 5">
                            <div class="logofront">
                                <img src="assets/img/webp-image/dedicated-team/dct1.webp" width="100" height="100"
                                    alt="image" />
                                <img src="assets/img/webp-image/dedicated-team/dct2.webp" width="100" height="100"
                                    alt="image" />
                                <img src="assets/img/webp-image/dedicated-team/dct3.webp" width="100" height="100"
                                    alt="image" />
                                <img src="assets/img/webp-image/dedicated-team/dct8.webp" width="100" height="100"
                                    alt="image" />
                                <img src="assets/img/webp-image/dedicated-team/bt8.webp" width="100" height="100"
                                    alt="image" />
                                <img src="assets/img/webp-image/dedicated-team/dc1.webp" width="100" height="100"
                                    alt="image" />
                                <img src="assets/img/webp-image/dedicated-team/radis.webp" width="100" height="100"
                                    alt="image" />


                                <img src="assets/img/webp-image/dedicated-team/dct5.webp" width="100" height="100"
                                    alt="image" />
                                <img src="assets/img/webp-image/dedicated-team/dct6.webp" width="100" height="100"
                                    alt="image" />
                                <img src="assets/img/webp-image/dedicated-team/dct7.webp" width="100" height="100"
                                    alt="image" />

                            </div>
                        </div>
                        <div class="tab tabing6" *ngIf="tabbing == 6">
                            <div class="logofront">
                                <img src="assets/img/webp-image/dedicated-team/dm1.webp" width="100" height="100"
                                    alt="image" />
                                <img src="assets/img/webp-image/dedicated-team/dm2.webp" width="100" height="100"
                                    alt="image" />
                                <img src="assets/img/webp-image/dedicated-team/dm3.webp" width="100" height="100"
                                    alt="image" />
                                <img src="assets/img/webp-image/dedicated-team/dm4.webp" width="100" height="100"
                                    alt="image" />
                                <img src="assets/img/webp-image/dedicated-team/dm5.webp" width="100" height="100"
                                    alt="image" />
                                <img src="assets/img/webp-image/dedicated-team/dm6.webp" width="100" height="100"
                                    alt="image" />
                                <img src="assets/img/webp-image/dedicated-team/dm7.webp" width="100" height="100"
                                    alt="image" />
                                <img src="assets/img/webp-image/dedicated-team/dm8.webp" width="100" height="100"
                                    alt="image" />
                                <img src="assets/img/webp-image/dedicated-team/dm9.webp" width="100" height="100"
                                    alt="image" />
                                <img src="assets/img/webp-image/dedicated-team/dm10.webp" width="100" height="100"
                                    alt="image" />
                                <img src="assets/img/webp-image/dedicated-team/dm11.webp" width="100" height="100"
                                    alt="image" />
                            </div>
                        </div>
                        <div class="tab tabing7" *ngIf="tabbing == 7">
                            <div class="logofront">
                                <img src="assets/img/webp-image/dedicated-team/qa1.webp" width="100" height="100"
                                    alt="image" />
                                <img src="assets/img/webp-image/dedicated-team/qa2.webp" width="100" height="100"
                                    alt="image" />
                                <img src="assets/img/webp-image/dedicated-team/qa3.webp" width="100" height="100"
                                    alt="image" />
                                <img src="assets/img/webp-image/dedicated-team/qa4.webp" width="100" height="100"
                                    alt="image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <app-testimonial-service profile="harvey-profile.png" clientname="Harvey Liptrot"
        designation="Managing Director, Springboard Supplies LTD" location="United Arab Emirates" flag="uk-flag.svg"
        clientcomment="Our experience with Rohit and his team has exceeded our expectations. The scope of work offered was flexible and tailored to suit our specific needs. Other IT companies said it would not be possible but Pro Web have made it work. Arshad and Manoj responded quickly to update the project based on feedback and all communication was handled seamlessly by Shrishti. We are glad to have found Pro Web and look forward to working with them on further projects with our other businesses in the future. Thank you for your dedication and commitment to the project!"
        rating="Rated 4.9 out of 5.0" [clientimg]="data" [clientmobile]="datamobile"
        clienttarget="The_travel_hub_logo.svg"></app-testimonial-service>


    <section class="dedicated-team-benefit">
        <div class="container">
            <div class="dedicated-team-benefit-blog">
                <h2 class="dedicated-title ">Our Worldwide <span>Presence</span></h2>
                <p class="text-center">We've served over 500 companies, empowering them to revolutionize their <br />
                    operations through
                    cutting-edge technological solutions.
                </p>
                <ul class="city-listed">
                    <li>
                        <img src="assets/img/webp-image/dedicated-team/citi2.webp" width="202" height="450"
                            alt="image" />

                        <div class="location-maping">
                            <span><img src="assets/img/webp-image/home/uaeflag2.webp" class="mapimg" width="30"
                                    height="20" alt="flag" /> United Arab Emirates</span>
                            <p>Office #1606, SIT Tower,
                                Dubai Silicon Oasis , Dubai
                                UAE</p>

                        </div>
                    </li>
                    <li>
                        <img src="assets/img/webp-image/dedicated-team/citi1.webp" width="202" height="450"
                            alt="image" />

                        <div class="location-maping">
                            <span><img src="assets/img/webp-image/home/india-flag-icon.webp" class="mapimg" width="30"
                                    height="20" alt="flag" /> India</span>
                            <p> 232, Zone-2 MP Nagar, Bhopal, Madhya
                                Pradesh, 462011</p>
                         
                        </div>
                    </li>

                    <li>
                        <img src="assets/img/webp-image/dedicated-team/citi3.webp" width="202" height="450"
                            alt="image" />

                        <div class="location-maping">
                            <span><img src="assets/img/webp-image/home/Canada_flag.webp" class="mapimg" width="30"
                                    height="20" alt="flag" /> Canada</span>
                            <p> 4025 River Mill Way Mississauga
                                Ontario, Canada
                                L4W 4C1</p>

                        </div>
                    </li>

                </ul>
            </div>
        </div>

    </section>

    <app-bottom-bar text="Ready To engage our developers?"></app-bottom-bar>


    <section class="spacing">
        <div class="container-md">
            <div class="row justify-content-center">
                <h2 class="faq-heading">Frequently <span>Asked Questions</span> </h2>
                <p class="my-3">Find the answers you need to know more about Dedicated Hiring</p>
                <div class="row my-4">
                    <div class="col-sm-6">
                        <div class="faq-list" *ngFor="let item of faq.slice(0, 4); let i = index">
                            <div class="faq-title d-flex justify-content-between" (click)="toggleAccordian($event, i)">
                                <h6>{{item.title}}</h6>
                                <h4 *ngIf="item.isActive == false"> + </h4>
                                <h4 *ngIf="item.isActive == true"> - </h4>
                            </div>
                            <div class="faq-des" *ngIf="item.isActive">
                                <div [innerHTML]="item.des"></div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6">
                        <div class="faq-list" *ngFor="let item of faq.slice(4); let i = index">
                            <div class="faq-title d-flex justify-content-between"
                                (click)="toggleAccordian($event, i+4)">
                                <h6>{{item.title}}</h6>

                                <h4 *ngIf="item.isActive == false"> + </h4>
                                <h4 *ngIf="item.isActive == true"> - </h4>
                            </div>
                            <div class="faq-des" *ngIf="item.isActive">
                                <div [innerHTML]="item.des"></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </section>

</section>