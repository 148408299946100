<!-- banner section -->
<app-anim-banner
  heading="Security testing to detect and resolve vulnerabilities in your Software"
  description="At Pro Web, we offer comprehensive penetration testing services to detect and resolve vulnerabilities in your system. Our experts evaluate your system to uncover and address potential security flaws, ensuring robust protection against cyber threats."
  image="vapt/vapt-anim.png"
></app-anim-banner>

<!-- services -->
<app-services-offer
  [cards]="cardsItems"
  firsttitle="Our Vulnerability Assessment"
  semititle="& Penetration Testing Services"
  description="Our skilled team conducts thorough assessments of your systems, pinpointing potential vulnerabilities and performing penetration tests to simulate real-world cyber threats. Safeguard your digital assets with proactive security measures tailored to meet your business requirements and ensure peace of mind against evolving cybersecurity risks."
></app-services-offer>

<!-- empower business -->
<div class="learn_business">
  <div class="container fullwidth">
    <div class="row align-items-center gy-3">
      <div class="col-md-5">
        <div>
          <h2>
            Benefits of Vulnerability Assessment Penetration Testing Service
          </h2>
          <p>
            Vulnerability Assessment and Penetration Testing (VAPT) services
            proactively identify and mitigate IT system weaknesses, enhancing
            cybersecurity resilience. By assessing software, network, and system
            vulnerabilities, businesses safeguard data, comply with regulations,
            and bolster security posture. Consistent VAPT ensures ongoing
            defense, providing actionable insights to allocate resources
            effectively and maintain industry standards, preserving
            organizational reputation in a resilient digital environment.
          </p>
        </div>
      </div>
      <div class="col-md-7">
        <div class="empower_wrapper">
          <div>
            <img
              src="../../assets/img/servicesdev/vapt/step_img.svg"
              alt=""
              srcset=""
            />
          </div>

          <div class="emplower_gif">
            <img
              src="../../assets/img/servicesdev/react-native/cost-saving.gif"
              alt=""
              srcset=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- flow diagram -->
<section class="flowWrapper">
  <div class="container text-center">
    <div class="flowContent">
      <h2>
        The phases of our assessment are defined in the flow diagram as below
      </h2>
      <p>
        Our industry expertise and research capabilities drive our leadership in
        developing advanced engagement models.
      </p>
    </div>

    <div class="my-5">
      <img
        src="../../assets/img/servicesdev/vapt/flow-diagram.png"
        alt=""
        srcset=""
      />
    </div>
  </div>
</section>

<!-- step process -->

<app-steps-choose
  heading="How It Works"
  description="Our VAPT process identifies vulnerabilities through thorough analysis and simulated attacks, providing detailed reports and recommendations to enhance your cybersecurity."
  [steps]="data"
></app-steps-choose>

<!-- why choose -->
<div class="why-choose">
  <div class="container">
    <div class="row align-items-center justify-content-between">
      <div class="col-md-6">
        <div>
          <h2>
            Build Trust Among Your Customers And Partners With a Security
            Certificate
          </h2>
          <p>
            Earn trust from customers and partners with a security certificate.
            Demonstrating compliance and robust security measures enhances
            credibility, ensuring confidence in safeguarding sensitive
            information and maintaining data integrity.
          </p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="mt-3 mt-md-0">
          <img
            src="../../assets/img/servicesdev/vapt/vapt-img.png"
            alt=""
            srcset=""
          />
        </div>
      </div>
    </div>
  </div>
</div>

<!-- contact -->
<app-bottom-bar
  text="Talk to Us: Secure Your System with Professional Guidance"
></app-bottom-bar>

<!-- faq's -->
<section class="spacing ">
  <div class="container-md">
    <div class="row justify-content-center justify-content-md-start">
      <h2>Frequently <span>Asked Questions </span></h2>
      <p class="my-3">
        Discover insights to deepen your grasp of VAPT fundamentals
      </p>

      <div class="row my-4">
        <div class="col-sm-6">
          <div
            class="faq-list"
            *ngFor="let item of faq.slice(0, 4); let i = index"
          >
            <div
              class="faq-title d-flex justify-content-between"
              (click)="toggleAccordian($event, i)"
            >
              <h6>{{ item.title }}</h6>

              <h4 *ngIf="item.isActive == false">+</h4>
              <h4 *ngIf="item.isActive == true">-</h4>
            </div>
            <div class="faq-des" *ngIf="item.isActive">
              <div [innerHTML]="item.des"></div>
            </div>
          </div>
        </div>

        <div class="col-sm-6">
          <div
            class="faq-list"
            *ngFor="let item of faq.slice(4); let i = index"
          >
            <div
              class="faq-title d-flex justify-content-between"
              (click)="toggleAccordian($event, i + 4)"
            >
              <h6>{{ item.title }}</h6>

              <h4 *ngIf="item.isActive == false">+</h4>
              <h4 *ngIf="item.isActive == true">-</h4>
            </div>
            <div class="faq-des" *ngIf="item.isActive">
              <div [innerHTML]="item.des"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
