
<app-scroll-progress-bar></app-scroll-progress-bar>

<app-header class="header-bar"></app-header>
<app-sticky-quoate-animation></app-sticky-quoate-animation>
<router-outlet></router-outlet>
<app-footer class="footer-bar" *ngIf="isFooterVisible"></app-footer> 



<!-- <div class="sticky-bottom p-0 d-lg-none relative stickBootomIcon" >
    <div class="sticky-bottom" style="display: flex;
        justify-content: flex-end;
        flex-direction: column;
        align-items: end;
        position: absolute;
        width: -webkit-fill-available;
        padding-right: 10px;
        padding-bottom: 15px;">
       
         <div class="d-flex justify-content-center align-middle rounded-circle "  style="background-color: #2D6FA6; width: fit-content; height:auto; padding: 13px; margin-bottom: 8px;">
             <a target="_blank" aria-label="telephone" href="tel:+971528050084">
                <img src="assets/img/webp/homepage/telephone-header.webp" alt="telephone" srcset="" style="width: 20px; height:20px;" />
            </a>
        </div> 
        <a target="_blank" aria-label="telephone" href="tel:+971526549225">
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" style="width: 50px; height:50px;">
                <circle cx="18" cy="18" r="18" fill="#4CAF50"/>
                <g clip-path="url(#clip0_1084_194)">
                <path d="M6.1755 18.0006V18.0001C6.1755 11.4791 11.4775 6.17573 17.9968 6.17573H18.0029C24.5221 6.17573 29.8242 11.4806 29.8242 18.0001C29.8242 24.5195 24.5221 29.8244 18.0029 29.8244L18.0021 29.8244C15.6891 29.8293 13.4264 29.1496 11.4989 27.8709L11.3638 27.7812L11.2093 27.8306L7.16974 29.122L8.47333 25.236L8.52882 25.0706L8.42607 24.9296C6.95965 22.9173 6.17145 20.4906 6.1755 18.0006Z" fill="#4CAF50" stroke="white" stroke-width="0.675676"/>
                <path d="M25.08 23.0123C24.7865 23.8409 23.622 24.528 22.6931 24.7287C22.0577 24.864 21.2276 24.972 18.4333 23.8135C14.8592 22.3328 12.5575 18.7009 12.3781 18.4652C12.2063 18.2296 10.9338 16.5421 10.9338 14.7968C10.9338 13.0515 11.8202 12.2017 12.1774 11.8368C12.4708 11.5373 12.9558 11.4005 13.421 11.4005C13.5715 11.4005 13.7068 11.4081 13.8284 11.4142C14.1857 11.4294 14.3651 11.4507 14.6007 12.0147C14.8941 12.7216 15.6087 14.4669 15.6938 14.6463C15.7805 14.8257 15.8671 15.0689 15.7455 15.3046C15.6315 15.5478 15.5311 15.6557 15.3517 15.8625C15.1724 16.0693 15.0021 16.2274 14.8227 16.4493C14.6585 16.6424 14.473 16.8492 14.6798 17.2064C14.8865 17.5561 15.6011 18.7221 16.6531 19.6586C18.0107 20.8672 19.1114 21.2534 19.5051 21.4176C19.7985 21.5392 20.1482 21.5103 20.3626 21.2823C20.6347 20.9889 20.9707 20.5024 21.3127 20.0235C21.556 19.6799 21.8631 19.6373 22.1854 19.759C22.5137 19.873 24.2514 20.7319 24.6087 20.9098C24.9659 21.0892 25.2016 21.1743 25.2882 21.3248C25.3734 21.4753 25.3734 22.1823 25.08 23.0123Z" fill="#FAFAFA"/>
                </g>
                <defs>
                <clipPath id="clip0_1084_194">
                <rect width="24.3243" height="24.3243" fill="white" transform="translate(5.83765 5.83789)"/>
                </clipPath>
                </defs>
            </svg>
        </a>
    </div>
</div> -->