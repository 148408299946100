<section class="spacing px-3 px-md-0 ">
  <div class="container-xl">
    <div class="row justify-content-center">
      <h2>
        {{ firsttitle }} <span> {{ semititle }} </span>
      </h2>
      <p class="my-3 desc spacingNewClss">{{ description }}</p>

      <div>
        <img
          src="../../assets/img/servicesdev/ring.webp"
          alt="ring"
          srcset=""
        />
      </div>

      <div class="row row-cols-md-2 g-2 g-md-4">
        <div class="col-md" *ngFor="let item of cards">

          <a href="{{ item.btnurl }}"  *ngIf="currentRoute == '/mobile-app-development-company/dubai' || currentRoute == '/web-development-company/dubai' ;else other_content">
          
            <div class="card cardMdNewClass"  *ngIf="currentRoute != '/mobile-app-development-company/dubai' || currentRoute != '/web-development-company/dubai'">
              <div class="globeimg">
                <img src="{{ item.img }}" alt="globe" srcset="" />
              </div>
              <div>
                <h4>{{ item.title }}</h4>
                <p class="textLimit">{{ item.desc }}</p>
              </div>
              <div class="mt-3">
                <button
                  [ngClass]="{
                    'dective-class': item.title == 'Cloud Based Servers'
                  }"
                  *ngIf="currentRoute == '/web-hosting-company/dubai'"
                  class="showbtn_active"
                >
                  <a href="{{ item.btnurl }}"
                    >{{ item.btnname }}
                    <img
                      src="../../assets/img/servicesdev/service-arrow.svg"
                      class="px-2 d-none d-md-inline-block"
                      alt="arrow"
                      srcset=""
                    />
                  </a>
                </button>
  
                <button
                  *ngIf="
                    currentRoute == '/cloud-hosting-company/dubai' &&
                    (item.title === 'Amazon Web Servers' ||
                      item.title === 'Digital Ocean')
                  "
                  class="showbtn d-none"
                  (click)="onPackageChange(item.title)"
                >
                  {{ item.btnname }}
                  <img
                    src="../../assets/img/servicesdev/service-arrow.svg"
                    class="px-2 d-none d-md-inline-block"
                    alt="arrow"
                    srcset=""
                  />
                </button>
  
                <button
                  *ngIf="
                    currentRoute == '/cloud-hosting-company/dubai' &&
                    (item.title == 'Microsoft Azure' ||
                      item.title == 'Google Cloud')
                  "
                  class="showbtn d-none"
                >
                  {{ item.btnname }}
                  <img
                    src="../../assets/img/servicesdev/service-arrow.svg"
                    class="px-2 d-none d-md-inline-block"
                    alt="arrow"
                    srcset=""
                  />
                </button>
              </div>
            </div>
  
          </a>
          <ng-template #other_content>
          <div class="card2"  *ngIf="currentRoute != '/mobile-app-development-company/dubai' || currentRoute != '/web-development-company/dubai'">
            <div class="globeimg">
              <img src="{{ item.img }}" alt="globe" srcset="" />
            </div>
            <div>
              <h4>{{ item.title }}</h4>
              <p class="textLimit">{{ item.desc }}</p>
            </div>
            <div class="mt-3">
              <button
                
                *ngIf="currentRoute == '/web-hosting-company/dubai'"
                class="showbtn_active"
              >
                <a href="{{ item.btnurl }}"
                  >{{ item.btnname }}
                  <img
                    src="../../assets/img/servicesdev/service-arrow.svg"
                    class="px-2 d-none d-md-inline-block"
                    alt="arrow"
                    srcset=""
                  />
                </a>
              </button>

              <button
                *ngIf="
                  currentRoute == '/cloud-hosting-company/dubai' &&
                  (item.title === 'Amazon Web Servers' ||
                    item.title === 'Digital Ocean')
                "
                class="showbtn d-none"
                (click)="onPackageChange(item.title)"
              >
                {{ item.btnname }}
                <img
                  src="../../assets/img/servicesdev/service-arrow.svg"
                  class="px-2 d-none d-md-inline-block"
                  alt="arrow"
                  srcset=""
                />
              </button>

              <button
                *ngIf="
                  currentRoute == '/cloud-hosting-company/dubai' &&
                  (item.title == 'Microsoft Azure' ||
                    item.title == 'Google Cloud')
                "
                class="showbtn d-none"
              >
                {{ item.btnname }}
                <img
                  src="../../assets/img/servicesdev/service-arrow.svg"
                  class="px-2 d-none d-md-inline-block"
                  alt="arrow"
                  srcset=""
                />
              </button>
            </div>
          </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</section>
