import { Component } from '@angular/core';
import { AfterViewInit } from '@angular/core';
import { ElementRef, ViewChild } from '@angular/core';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

@Component({
  selector: 'app-sticky-quoate-animation',
  templateUrl: './sticky-quoate-animation.component.html',
  styleUrls: ['./sticky-quoate-animation.component.css']
})
export class StickyQuoateAnimationComponent implements AfterViewInit {
  isModalOpen = false;

  @ViewChild('content', { static: true }) contentRef!: ElementRef;
   constructor(){}

  // ngAfterViewInit(): void {
  //   const boxes = document.querySelectorAll<HTMLElement>('.box1, .box2');
  //   boxes.forEach((box) => {
  //     ScrollTrigger.create({
  //       trigger: box,
  //       start: 'top top',
  //       end: '+=300',
  //       onEnter: () => {
  //         box.style.transform = 'translateX(0)';
  //         console.log(`${box.className} is now visible`);
  //       },
  //       onLeaveBack: () => {
  //         box.style.transform = 'translateX(100%)';
  //         console.log(`${box.className} is hidden`);
  //       },
  //     });
  //   });
  // }

  // ngAfterViewInit(): void {
  //   const boxes = document.querySelectorAll<HTMLElement>('.box1, .box2');
  //   boxes.forEach((box) => {
  //     ScrollTrigger.create({
  //       trigger: box,
  //       start: 'top top',  
  //       end: '+=300',    
  //       onEnter: () => {
  //         box.style.transform = 'translateX(0)';
  //         console.log(`${box.className} is now visible`);
  //       },
  //       onLeaveBack: () => {
  //         box.style.transform = 'translateX(100%)';
  //         console.log(`${box.className} is hidden`);
  //       },
  //     });
  //     const hideOnBottom = () => {
  //       if (window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 1000) {
  //         console.log(document.documentElement.scrollHeight - 1000, "dddddddddddddddddddddddd")
  //         box.style.transform = 'translateX(100%)';  
  //         console.log(`${box.className} is hidden at the bottom of the screen`);
  //       } else {
  //         box.style.transform = 'translateX(0)';  
  //       }
  //     };
  
  //     window.addEventListener('scroll', hideOnBottom);
  //   });
  // }
 
  
  ngAfterViewInit(): void {
    const boxes = document.querySelectorAll<HTMLElement>('.box1, .box2');
    
    boxes.forEach((box) => {
      ScrollTrigger.create({
        trigger: box,
        start: 'top top',  
        end: '+=300',
        onEnter: () => {
          box.style.transform = 'translateX(0)';
          // console.log(`${box.className} is now visible`);
        },
        onLeave: () => {
          box.style.transform = 'translateX(100%)';
          // console.log(`${box.className} is hidden`);
        },
        // onLeaveBack: () => {
        //   box.style.transform = 'translateX(100%)'; 
        //   console.log(`${box.className} dd is hidden when scrolling up`);
        // },
      });

      // const hideOnBottom = () => {
      //   if ((window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 1000) || window.scrollY<300) {
      //     box.style.transform = 'translateX(100%)';
      //     // console.log(`${box.className} is hidden at the bottom of the screen`);
      //   } else {
      //     box.style.transform = 'translateX(0)';
      //   }
      // };
      const hideOnBottom = () => {
        const isSmallScreen = window.innerWidth <= 768; // Define the small screen width breakpoint
      
        if (
          ((window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - (isSmallScreen ? 1980 : 850))) || 
          window.scrollY < 300
        ) {
          box.style.transform = 'translateX(100%)';
          // console.log(`${box.className} is hidden at the bottom of the screen`);
        } else {
          box.style.transform = 'translateX(0)';
        }
      };
      window.addEventListener('scroll', hideOnBottom);
    });
  }
  
 
  
  
  

  openModal() {
    this.isModalOpen = true;
  }

  closeModal() {
    this.isModalOpen = false;
  }

}
