<section class="spacing spacingParantNewDivClass">
  <div class="container-md">
    <div class="row justify-content-center">
      <h2>Engagement <span> Models</span></h2>
      <p class="my-3">
        Our industry expertise and research capabilities drive our leadership in
        developing advanced engagement models.
      </p>

      <div class="modalwrap row justify-content-between gy-3 gy-md-0">
        <div class="col-md">
          <div class="modalcard">
            <h4>Time And Material</h4>

            <div class="globe">
              <img
                src="../../assets/img/servicesdev/globe-transparent.webp"
                alt="ring"
                srcset=""
              />
            </div>

            <div class="content">
              <div class="txt">This model is for you if</div>

              <p>It’s hard to determine the amount of work.</p>
              <p>The technical documentation is poor or absent.</p>

              <div class="txt">How it works</div>

              <p>Pay only for the working hours spent.</p>

              <div class="txt">Project duration (months)</div>

              <p>1+</p>

              <div class="txt">Billing</div>

              <p>Hourly based invoice</p>
            </div>
          </div>
        </div>

        <div class="col-md">
          <div class="modalcard">
            <h4>Fixed Price</h4>

            <div class="globe">
              <img
                src="../../assets/img/servicesdev/globe-transparent.webp"
                alt="ring"
                srcset=""
              />
            </div>

            <div class="content">
              <div class="txt">This model is for you if</div>

              <p>Project technical documentation available.</p>
              <p>Clear requirements about your website or portal.</p>

              <div class="txt">How it works</div>

              <p>Develop your project within the exact budget.</p>

              <div class="txt">Project duration (months)</div>

              <p>1-3</p>

              <div class="txt">Billing</div>

              <p>Cost based on estimates</p>
            </div>
          </div>
        </div>

        <div class="col-md">
          <div class="modalcard">
            <h4>Dedicated Team</h4>

            <div class="globe">
              <img
                src="../../assets/img/servicesdev/globe-transparent.webp"
                alt="ring"
                srcset=""
              />
            </div>

            <div class="content">
              <div class="txt">This model is for you if</div>

              <p>You need a full-time team.</p>
              <p>You want to control the development process.</p>

              <div class="txt">How it works</div>

              <p>Hire your own flexible team with the skill set you need.</p>

              <div class="txt">Project duration (months)</div>

              <p>3+</p>

              <div class="txt">Billing</div>

              <p>Hourly based invoice</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
